.clients-read__form {
  margin-bottom: 20px;
}

.checkbox-raw input[type="checkbox"] {
  position: relative !important;
  left: 0;
  opacity: 1 !important;
  width: 22px;
  height: 16px;
}

.btn-form-options {
  background: white !important;
  border-color: white !important;
  font-weight: 400 !important;
  color: #c4c4c4 !important;
  text-decoration: none !important;
}

.page-title{
  font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 27px;
color: #FF5B22;
}