@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.organization-row {
      td:nth-of-type(1):before { content: "Logo"; }
      td:nth-of-type(2):before { content: "Name"; }
      td:nth-of-type(3):before { content: "Status"; }
    }
  }
.abs{
  position: absolute;
}



.logo-uploader {
  width: 320px;
  height: 320px;
  // background-size: 320px 320px;
}

.header-uploader {
  width: 820px;
  height: 360px;
  // background-size: 820px 360px;
}
