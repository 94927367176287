.form-group {
  label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #CECCE3;
    margin: 0 0 10px 0;
  }
}

.form-compact {
  .form-fields {
    max-width: 820px;
  }
}

.form-actions {
  margin: 10px 0;
  padding-top: 20px;
  border-top: 1px solid #E6E5EE;
}

.select__multi-value__label {
  border-radius: 3px 0 0 3px !important;
}
.select__multi-value__remove {
  border-radius: 0 3px 3px 0 !important;
}

.select__multi-value__label, .select__multi-value__remove {
  background-color: #FF5B22;
  color: white;
}

.select__multi-value__label {
  color: white !important;
}

.select__multi-value {
  line-height: 24px;
}


.form-section-header {
  margin: 30px 0 10px 0;
  h5 {
    padding: 10px 0;
    font-family: $default-font;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #120F46;
  }

}

.SelectFromArray {
  option {
    text-transform: capitalize;
  }
}

.form-section {
  padding: 20px 0;
  margin-top: 20px;

  &.with-border-top {
    border-top: 1px solid #CECCE3;
  }
}

div.is-invalid ~ .invalid-feedback {
  display: block;
}

.datetime-input {
  .react-datepicker-wrapper {
    width: 100%;
  }
}