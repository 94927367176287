.TableEventReport {
}

.phase-row td:first-child {
  padding-left: 32px;
}

@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.head-row {
      td:nth-of-type(1):before { content: "Ticket type"; }
      td:nth-of-type(2):before { content: "Capacity"; }
      td:nth-of-type(3):before { content: "Sold count"; }
      td:nth-of-type(4):before { content: "Available"; }
      td:nth-of-type(5):before { content: "" }
      td:nth-of-type(5) { display: none; }
      td:nth-of-type(6):before { content: "Total" }
    }

    tr.phase-row {
      td:nth-of-type(1):before { content: "Phase"; }
      td:nth-of-type(2):before { content: "Capacity"; }
      td:nth-of-type(3):before { content: "Sold count"; }
      td:nth-of-type(4):before { content: "Avaialable"; }
      td:nth-of-type(5):before { content: "Unit price" }
      td:nth-of-type(6):before { content: "Total" }
    }

    tr.product-head-row {
      td:nth-of-type(1) { color: transparent; }
      td:nth-of-type(5) { display:none; }

      td:nth-of-type(1):before { content: "Product & Services (Summary)"; }
      td:nth-of-type(2):before { content: "Capacity"; }
      td:nth-of-type(3):before { content: "Sold count"; }
      td:nth-of-type(4):before { content: "Avaialable"; }
      td:nth-of-type(5):before { content: ""; }
      td:nth-of-type(6):before { content: "Total" }
    }

    tr.product-row {
      td:nth-of-type(1):before { content: "Product / Service"; }
      td:nth-of-type(2):before { content: "Capacity"; }
      td:nth-of-type(3):before { content: "Sold count"; }
      td:nth-of-type(4):before { content: "Avaialable"; }
      td:nth-of-type(5):before { content: "Unit price" }
      td:nth-of-type(6):before { content: "Total" }
    }

  }
