.dropzone-uploader-wrapper {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position-y: center;
  background-color: #F9F8FB;
  background-size: 100%;
  border-radius: 6px;

  .dzu-dropzone {
    display: table;
    width: 100% !important;
    height: 100% !important;
    border: none;
    position: relative;
  }

  .dzu-dropzone label {
    width: 100% !important;
    height: 100% !important;
    display: table;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    .btn {
      background: transparent;
      border: 1px solid rgba(18, 15, 70, 0.25);
      box-sizing: border-box;
      border-radius: 6px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      text-align: center;

      color: #120F46;
    }
  }

  .dzu-dropzone .uploader-content {
    width: 100% !important;
    height: 100% !important;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  &.has-content {
    .dzu-dropzone .uploader-content {
      display: none;
    }
  }
  &.has-content:hover {
    .dzu-dropzone .uploader-content {
      display: table-cell;
    }
  }

}

