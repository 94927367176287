tr {
  border-bottom: 1px solid #cacaca;
}

tr:last-child {
  border-bottom: none;
}

td,
th {
  padding: 15px;
}

.TableEventReport {
  width: 100%;
}


.graph-title {
  margin-top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #120F46;
}

@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {

    .hideable-content {
      display:none;
    }

    #chartdiv {
      width: 100% !important;
    }

    .display-in-columns {
      flex-direction: column;
    }

    #chartdiv20, #pie-chart {
      width:100% !important;
      height: 300px !important;
    }

    .full-width {
      width: 100% !important;
    }

    .graph-title {
      padding: 0;
      margin: 14px auto;
      text-align: center;
      font-size: 16px !important;
    }

  }
.filter_event_view{
  margin-top: 20px;
}
