@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.event-row {
      td:nth-of-type(1):before { content: "Organization"; }
      td:nth-of-type(2):before { content: "Event"; }
      td:nth-of-type(3):before { content: "Starts/Ends"; }
      td:nth-of-type(4):before { content: "Status"; }
      td:nth-of-type(10):before { content: ""; }
    }

    .logo-uploader {
      width: 125px !important;
      height: 125px !important;
    }

    .header-uploader {
      width: 335px !important;
      height: 140px !important;
    }

  }

  .logo-uploader {
    width: 320px;
    height: 320px;
    // background-size: 320px 320px;
  }

  .header-uploader {
    width: 820px;
    height: 360px;
    // background-size: 820px 360px;
  }
