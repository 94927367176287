@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.phases-index-row {
      td:nth-of-type(1):before { content: "Phase"; }
      td:nth-of-type(2):before { content: "Availability"; }
    }
  }


.ticket-header-uploader {
  width: 334px;
  height: 148px;
  border-radius: 15px;
  margin-bottom: 20px;
}
