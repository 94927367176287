@import "./variables";

ul.pagination {
  display: block;
  text-align: right;
  li {
    display: inline-block;

    width: 28px;
    line-height: 1;
    border-radius: 6px;
    background-color: #e6e5ee;
    text-align: center;

    a {
      display: block;
      width: 100%;
      height: 28px;
      padding-top: 5px;

      font-family: $default-font;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: white;
    }

    &:hover {
      background-color: $handy-orange;
    }
  }
  li:not(:last-child) {
    margin-right: 10px;
  }

  li.active {
    background-color: $handy-blue;
    a {
      color: white;      
    }
  }
}