@import "./variables";

.btn {
  &.btn-primary {
    background-color: $handy-blue;
    border: none;
  }
  &.btn-primary:hover {
    background-color: $handy-blue;
    opacity: 0.8;
  }
}

.table-actions {
  margin-bottom: 20px;
}

.badge {
  &.rounded {
    border-radius: 100px;
  }

  &.green {
    background: #8dc878;
  }
}

.btn-cancel-form {
  background: #f7f7f9;
  color: $handy-blue;
}

.form-actions {
  .Button,
  .btn {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    line-height: 35px;
    padding: 5px 26px;
  }
  .Button {
    width: auto;
  }
  .btn {
    margin-right: 10px;
  }
}
