#rolesError {
  color: #dc3545 !important;
}

.form-horizontal label {
  margin-bottom: 10px !important;
}

.checkbox-success {
  margin-top: 10px;
}

.btnAdd {
  width: 13%;
  height: 53%;
  margin-top: 10%;
  margin-right: 1%;
  background-color: #ff5b22 !important;
  border-color: #ff5b22 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff5b22 !important;
  background-color: #ff5b22 !important;
}
@media screen and (max-width: 768px) {
  .btnAdd {
    width: 13%;
    height: 3%;
    margin-top: 3%;
    margin-right: 1%;
    background-color: #ff5b22 !important;
    margin-left: 5%;
  }
}
