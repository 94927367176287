.btn-delete {
  background: rgba(221, 52, 52, 0.07) !important;
  border-radius: 6px;
  border: none !important;

  color: #DD3434 !important;
  line-height: 24px !important;
  width: 100%;
}


.form-field-group {
  // border-left: 3px solid#FF5722;
  // padding: 10px;
  margin: 40px 0 20px 0;

  h5 {
    margin-bottom: 10px;
    display: block;
    padding-bottom: 2px;
  }
}

.btn-add, .btn-delete {
  font-family: Inter;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-align: center;
}

.btn-add {
  line-height: 36px !important;
}