@import "../../../layouts/App/theme/variables";

body {
  overflow-x:hidden;
}
.content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    }

.sidebar {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  width: 250px;

  .nav.flex-column {
    position: fixed;
    width: 251px;
    height: 100vh;
    background-color: $handy-blue;
    padding-top: 10px;
  }

  .nav-link {
    height: 50px;
    font-family: $default-font;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.5;

    .menu-icon-wrapper {
      width: 30px;
      height: 30px;
      text-align: center;
      margin-right: 18px;
      display: inline-block;
      img.icon {

      }
    }

    &.active {
      border-right: 3px solid #FF5B22;
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.main-content {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 2 1 auto;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  padding: 40px 20px 300px 20px
}

@media (max-width: 575px) {
  .main-content {
    max-width: 100%;
    overflow-x: scroll;
  }
}

@media (max-width: 767px) {
}

// @media (max-width: 991.98px) {
@media (max-width: 1024px) {

  .main-content {
    max-width: 100%;
    overflow-x: scroll;
  }
  .sidebar {
    width: 50px;

    .nav.flex-column {
      width: 50px;
      padding-top: 0;

      .nav-item {
        width: 50px;
      }

      .nav-link {
        text-indent: 0;
        padding: 10px;
      }

      .menu-label {
        display: none;
      }
    }
  }
}