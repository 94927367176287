@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.slider-item-row {
      td:nth-of-type(1):before { content: "Image"; }
      td:nth-of-type(2):before { content: "Content"; }
      td:nth-of-type(3):before { content: "Link"; }
      td:nth-of-type(4):before { content: "\A"; }
    }
  }
.abs{
  position: absolute;
}

.image-uploader {
  width: 800px;
  height: 320px;
  // background-size: 320px 320px;
}

.slider-item-content {
  p {
    font-size: 12px;
    margin: 0;
  }
}