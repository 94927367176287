.salesReportInfo {
  color: #120f46;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  div > div > small {
    color: #737188;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}
.row > div {
  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #120f46;
  }
}

.selectOrg {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #ff5b22;
  margin-bottom: 20px !important;
  border: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: white !important;
}

.form-row {
  div > div > select {
    border: 1px solid #e6e5ee;
    box-sizing: border-box;
    border-radius: 6px;
  }
}
.breadcrumb{
    background-color: white !important;
}