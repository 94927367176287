/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
.collapsible-menu {
  background-color: #120F46;
  position: absolute;
  left: 0;
  top: 58px;
  transition: none !important;
  -webkit-transition: none !important; }
  .collapsible-menu.collapsing {
    transition: none !important;
    -webkit-transition: none !important;
    height: auto !important;
    overflow: visible !important;
    position: absolute;
    animation: none !important; }
  .collapsible-menu .nav-link {
    padding: 20px !important;
    color: white; }
    .collapsible-menu .nav-link .menu-icon-wrapper {
      margin-right: 10px; }
