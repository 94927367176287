@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.ticket-type-row {
      td:nth-of-type(1):before { content: "Coupon"; }
      td:nth-of-type(2):before { content: "Code"; }
      td:nth-of-type(2):before { content: "Usage"; }
      td:nth-of-type(2):before { content: "Status"; }
    }
    .btn-export-xls {
      width: 100%;
    }
    .table-actions {
      margin-top: 5px;
    }
  }


.coupon-code {
  font-family: MONOSPACE;
  font-size: 17px;
}