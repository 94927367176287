.btn-filter {
  background-color: #120f4647 !important;
  margin-right: 5px;
  color: white !important;
  border-radius: 6px;
}
.btn-resend, .btn-orange {
  background-color: #ff5b22 !important;
  color: white !important;
}
.btn-export {
  background-color: #120f46;
  width: 100%;
}
.btn-show {
  background: #f2f1f6 !important;
  width: 100%;
  color: #57547E !important;
}
.select-filter {
  margin-left: 39%;
  width: 106px !important;
  height: 39px !important;
  background-color: #120f46 !important;
  border-radius: 6px !important;
  color: #fff !important;
}
.active-filter {
  background: #120f46 !important;
  color: white !important;
  margin-right: 1%;
  border-radius: 6px;
}
.dropdown-filter {
  border: 1px solid #e6e5ee;
  box-sizing: border-box;
  border-radius: 6px;
  display: block;
  /* width: 100%; */
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

p {
  margin-left: 15px;
  margin-right: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  color: #ff5b22;
}

.search-order input {
  width: 96%;
}
.list-filter {
  margin-top: 3%;
}
.list-filter select {
  margin-left: 1%;
}
.abs {
  position: absolute;
}
.mr-bottom{
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .list-filter {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .abs {
    position: initial;
  }
}

.breadcrumb-item {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #ff5b22;
}
.currentOrder {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #120f46;
}
.breadcrumb-item.active {
  color: #120f46 !important;
  text-decoration: underline;
  text-decoration-color: #120f46;
}
.orderResume {
  border: 1px solid #e6e5ee;
  box-sizing: border-box;
  border-radius: 6px;
  div > div {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #cecce3;
    margin-top: 0.5rem;
  }
}
.infoItem {
  font-family: Inter;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: right !important;
  color: #57547e !important;
}
.infoStatus {
  font-family: Inter;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: right !important;
  color: #8dc878 !important;
}
.tittleResume {
  padding: 18px 1.25rem;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #120f46;
}
.bodyResume {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.btnSendTickets {
  background-color: #ff5b22 !important;
  border-radius: 6px !important;
  border-color: #ff5b22 !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ff5b22 !important;
  border: none;
  border-bottom: 2px solid #ff5b22 !important;
}

.nav-tabs .nav-link {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  padding-right: 2rem;
  padding-left: 2rem;
  color: #120f46;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: white !important;
}
