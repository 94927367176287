@import "./variables";

table {
  width: 100%;
  border-collapse: collapse;

  th {
    font-family: $default-font !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #CECCE3 !important;
    border: none !important;
    box-shadow: 0px 1px 0px #E5E5EE !important;
    padding: 16px 5px !important;
    vertical-align:middle;
  }

  td {
    font-family: $default-font !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #8d8ca3;
    padding: 16px 5px;
    vertical-align:middle;
    // color: #120F46;
  }

  td:before {
    vertical-align:middle;
    font-size: 14px;
    line-height: 17px;
    color: #120F46;
    opacity: 0.6;;
  }

  .table-img {
    width: 50px;
    height: 50px;
  }

  &.table.large.large {
    th, td {
      padding: 29px auto !important;
    }
  }
}

td, th {
  vertical-align: middle !important;
}

/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
	@media
	  only screen
    and (max-width: 760px), (min-device-width: 768px)
    and (max-device-width: 1024px)  {

    table.responsive {
      /* Force table to not be like tables anymore */
      thead, tbody, th, td, tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        margin: 0 0 1rem 0;
        border: 1px solid #ffeeee;
        box-shadow: 0 2px 2px #E5E5EE !important;
        border-radius: 4px;

      }

      td:not(.full-width) {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px dotted #E5E5EE !important;
        position: relative;
        padding-left: 45% !important;
      }

      td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 12px;
        left: 8px;
        width: 40%;
        padding-right: 10px;
        white-space: nowrap;
      }
    }

  }

  .row-as-table {
    display: table;
    & > div {
      display: table-cell;
      flex: none;
      padding-left: 10px;
    }

    .random-pass-option {
      width: 140px;
    }
    .custom-pass-option {
      width: 260px;
    }
    .pass-field {
      width: 300px;
    }
  }