.lazur-bg, .bg-info {
  background-color: #23c6c8 !important;
  color: #ffffff;
}

.yellow-bg, .bg-warning {
  background-color: #f8ac59 !important;
  color: #ffffff;
}

.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;

  &.with-color {
    h1, h3 {
      color: #fff;
    }

    svg {
      color: #fff;
    }
  }
  h1 {
    font-weight: 100;
  }
}

.pull-right {
  float: right;
}

@media (max-width: 575px) {
  .list-group-item  li {
    margin: 10px 0;
    line-height: 27px;
    display: block;
    float: left;
    width: 100%;
  }
}

.checkin-report thead > tr > th:nth-child(2),
.checkin-report tbody > tr > td:nth-child(2) {
  text-align: right;
}


table.table.checkint-report.table.large {
  th, td {
    padding: 29px auto !important;
  }
}

.p-large {
  padding: 29px !important;
}

.p-l-20 {
  text-indent: 20px;
}

@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {

    // .checkin-report thead > tr > th:nth-child(2),
    // .checkin-report tbody > tr > td:nth-child(2) {
    //   text-align: left;
    // }

    .p-l-20 {
      text-indent: 20px;
    }

  }
