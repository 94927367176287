.activeLink {
  // border-right: 3px solid #ff5b22;
  border-left: 3px solid #ff5b22;
  text-indent: 10px;
}

.nav.flex-column {
  padding-top: 10px;
}

.event-sidebar-menu {
  // flex: 0 0 220px !important;
  // max-width: 220px !important;
  flex: 0 0 310px !important;
  max-width: 310px !important;
  padding-left: 30px;
}

.event-sidebar .nav-item a.nav-link {
  color: #120f46;
  padding: 0px;
  margin: 8px;
  font-size: 14px;
  font-family: "Inter";
  font-weight: normal;
}

.event-sidebar .nav-item a.nav-link.disabled {
  color: #bbb;
}
.event-sidebar .nav-item a.nav-link.activeLink {
  color: #ff5b22;
}

.event-logo {
  width: 125px;
  height: 125px;
  border-radius: 15px;
  margin-bottom: 20px;
  object-fit: cover;
}

.nav-item {

  .nav-link {
    padding-left: 0;
  }

  &.header {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #737188;
    opacity: 0.5;
    margin-bottom: 10px;
  }
}


.event-dropdown-menu {
  width: 100%;
  margin: 20px auto;
  background-color: transparent !important;
  color: #120F46 !important;
  border: 1px solid #E6E5EE !important;
  text-align: left !important;
  display: none;
}

.event-dropdown-menu.dropdown-toggle::after {
  float: right !important;
  margin-top: 9px !important;
}

.event-dropdown-menu-wrapper {
  display: none;
}

@media (max-width: 575px) {
  .event-sidebar-menu {
    display: none;
  }

  .event-dropdown-menu-wrapper {
    display: block;
  }
}

@media (max-width: 767px) {

}

@media (max-width: 1024px) {
  .event-sidebar-menu {
    flex: 0 0 200px !important;
    max-width: 200px !important;
  }
}
