/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
.handypass-navigation {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08); }
  .handypass-navigation .logo {
    width: 114px; }
  .handypass-navigation .navbar-brand {
    margin: 0 auto; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.user-badge {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #FF5B22;
  color: white;
  line-height: 31px;
  margin: auto;
  text-align: center;
  text-transform: uppercase; }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5"); }

@media (max-width: 575px) {
  aside.sidebar {
    display: none; }
  .navbar-brand {
    text-indent: -40px; }
  .user-badge {
    position: absolute;
    right: 10px; }
  .user-menu {
    position: absolute;
    right: 0;
    top: 5px; }
  .navbar-nav .dropdown-menu {
    margin-top: 37px; } }

@media (max-width: 767px) {
  .navbar-brand {
    text-indent: -40px; } }
