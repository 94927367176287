@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.product-row {
      td:nth-of-type(1):before { content: ""; }
      td:nth-of-type(2):before { content: "Product name"; }
      td:nth-of-type(3):before { content: "Availability"; }
      td:nth-of-type(4):before { content: ""; }
    }
  }

.product-header-uploader {
  width: 334px !important;
  height: 148px !important;
}
