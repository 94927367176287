.Button {
  height: 45px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  shadow: none;
  font-size: 14px;
  margin: 5px 0;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  font-family: inherit;
  font-weight: 500;
  background-color: white;
  border: 1.5px solid #120f46;
  color: #120f46;
  white-space: nowrap;
  width: 100%;
  &.color {
    background-color: #120f46;
    color: white;
  }
  &.disabled,
  &.isLoading {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
