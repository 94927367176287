@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.user-row {
      td:nth-of-type(1):before { content: "First name"; }
      td:nth-of-type(2):before { content: "Last name"; }
      td:nth-of-type(3):before { content: "Email"; }
      td:nth-of-type(4):before { content: "Roles"; }
      td:nth-of-type(10):before { content: ""; }
    }

  }

.permissions-row {
  position: relative;
  .add-buttons {
    width: 120px;
    position: absolute;
    left: calc(100% + 12px);
    top: 20px;

    .btn {
      width: 46px;
      height: 37px;
      margin-top: 11px;
      margin-right: 5px;
      background-color: #FF5B22 !important;
      border-color: #FF5B22 !important;
      display: inline-block !important;
    }
  }
}
