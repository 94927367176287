.btn_section_filter {
  button {
    margin-right: 3px !important;
  }
}

.btn_add_deposit {
  background-color: #ff5b22 !important;
  border-color: #FF5B22 !important;
  border-radius: 6px !important;
}

.down-search {
  display: none !important;
}


@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    tr.deposit-row {
      td:nth-of-type(1):before { content: "Date"; }
      td:nth-of-type(2):before { content: "Deposit ID"; }
      td:nth-of-type(3):before { content: "Organization"; }
      td:nth-of-type(4):before { content: "Status"; }
      td:nth-of-type(5):before { content: "Type"; }
      td:nth-of-type(6):before { content: "Bank"; }
      td:nth-of-type(7):before { content: "Transaction Code"; }
      td:nth-of-type(8):before { content: "Total"; }
    }

  }

@media (max-width: 575px) {
  .top-search {
    display: none;
  }
  .down-search {
    display: block !important;
  }

  .xs-full-width { width: 100%; }
}
