/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
.v-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.login-form {
  width: 360px;
  padding: 10px; }
  .login-form .logo {
    margin: 0 auto 40px auto;
    display: block; }
  .login-form .form-control {
    margin-bottom: 10px; }
  .login-form input {
    border: 1px solid #E6E5EE;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    background-color: #f9f8fb;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px; }
    .login-form input:focus {
      background-color: white; }
  .login-form .actions {
    margin-top: 20px; }
  .login-form .btn-blue {
    background: #120F46;
    border-radius: 6px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 13px;
    color: #FFFFFF; }
  .login-form .error-message {
    margin-top: 46px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #DD3434; }
