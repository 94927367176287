.btnCard {
  text-align: right;
  margin: 0px !important;
  padding: 0px;
}

.order-details {
  .nav {
    margin-top: 20px;
    display: inline-flex !important;
    flex-wrap: nowrap !important;
  }

  .nav-item {
    .nav-link {
      padding-left: 0 !important;
      padding-right: 20px !important;
      padding-bottom: 18px;

      &.active {
        border-width: 3px;
      }
    }
  }

  .nav-tabs {
    display: inline-flex;
    width: 100%;
    overflow-x: auto;
    border-bottom: 2px solid #ddd;
    -ms-overflow-style: none; /*// IE 10+*/
    overflow: -moz-scrollbars-none; /*// Firefox*/
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:focus,
  .nav-tabs > li.active > a:hover {
    border-width: 0;
  }
  .nav-tabs > li > a {
    border: none;
    color: #666;
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li > a:hover {
    border: none;
    color: #4285f4 !important;
    background: transparent;
  }
  .nav-tabs > li > a::after {
    content: "";
    background: #4285f4;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
  }
  .nav-tabs > li.active > a::after,
  .nav-tabs > li:hover > a::after {
    transform: scale(1);
  }
  .tab-nav > li > a::after {
    background: #21527d none repeat scroll 0% 0%;
    color: #fff;
  }
  .tab-pane {
    padding: 15px 0;
  }
  .tab-content {
    padding: 20px;
  }

  .nav-tabs::-webkit-scrollbar {
    display: none; /*Safari and Chrome*/
  }
  .card {
    background: #fff none repeat scroll 0% 0%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
  }
}
