@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    .down-search {
      width: 100%;
      .form-inline {
        display: block !important;
      }

    }
    .orders-page .table-actions input {
      width: 100% !important;
    }

    tr.order-row {
      td:nth-of-type(1):before { content: "Dtate"; }
      td:nth-of-type(2):before { content: "Order Id"; }
      td:nth-of-type(3):before { content: "Event"; }
      td:nth-of-type(4):before { content: "Status"; }
      td:nth-of-type(5):before { content: "Client"; }
      td:nth-of-type(6):before { content: "Quantity"; }
      td:nth-of-type(7):before { content: "Subtotal"; }
      td:nth-of-type(8):before { content: "Comissions"; }
      td:nth-of-type(9):before { content: "Total"; }
      td:nth-of-type(10):before { content: ""; }
    }

    .logo-uploader {
      width: 125px !important;
      height: 125px !important;
    }

    .header-uploader {
      width: 335px !important;
      height: 140px !important;
    }

  }

  .down-search {
    display: none;
  }

@media (max-width: 575px) {
  .top-search {
    display: none;
  }
  .down-search {
    display: block;
  }
}

.orders-page {
  .table-actions {
    margin-bottom: 20px;
    margin-top: 12px;
    input, button {
      display: inline-block;
    }
    input {
      width: 200px;
      margin-right: 5px;
    }
    button {
      margin-top: -5px;
    }
  }
}

.date-filters {
  width: 360px;
  margin-left: 10px;
}


.orders-date-range {
  .react-datepicker-wrapper {
    width: 160px;
    margin-right: 5px;
  }
}

.btn-show-filters{
  background-color: #F2F1F6 !important;
  border-radius: 6px !important;
  color: #57547E !important;
  border-color: #F2F1F6 !important;
}